module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wojciech Bator","short_name":"Wojciech Bator","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/wojciechbator.webp","cache_busting_mode":"none","prefer_related_applications":true,"icons":[{"src":"src/images/wojciechbator72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"src/images/wojciechbator96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"src/images/wojciechbator128.png","sizes":"128x128","type":"image/png","purpose":"any maskable"},{"src":"src/images/wojciechbator144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"src/images/wojciechbator152.png","sizes":"152x152","type":"image/png","purpose":"any maskable"},{"src":"src/images/wojciechbator192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"src/images/wojciechbator384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"src/images/wojciechbator512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
